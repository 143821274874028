<template>
  <div>
    <page-header has-back></page-header>
    <div class="layout-main-body">
      <el-card shadow="never" v-loading="pageLoading" v-cloak>
        <el-row>
          <el-col>
            <el-form :model="ruleForm" ref="ruleForm" label-width="200px">
              <el-form-item label="当前用户">
                <span>{{ ruleForm.nickName }}{{ '(' + ruleForm.mobile + ')' }}</span>
              </el-form-item>
              <el-form-item label="二维码">
                <el-upload
                    class="avatar-uploader"
                    action="#"
                    :show-file-list="false"
                    :limit="1"
                    :before-upload="handleHoverBeforeUpload">
                  <img v-if="ruleForm.qrUrl" :src="ruleForm.qrUrl" referrer-policy="No Referrer" class="userPic">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="所属行业">
                <el-select v-model="ruleForm.category" placeholder="所属行业" class="change-width">
                  <el-option v-for="item in categoryList" :label="item.name" :value="item.id"
                             :key="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="真实姓名">
                <el-input v-model="ruleForm.realName" class="change-width"></el-input>
              </el-form-item>
              <el-form-item label="职位">
                <el-input v-model="ruleForm.post" class="change-width"></el-input>
              </el-form-item>
              <el-form-item label="个性签名">
                <el-input
                    type="textarea"
                    placeholder="请输入内容"
                    v-model="ruleForm.personalSignature"
                    rows="4"
                    maxlength="50"
                    class="change-width"
                    show-word-limit>
                </el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-radio-group v-model="ruleForm.sex">
                  <el-radio :label="1">男</el-radio>
                  <el-radio :label="2">女</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="公司名称">
                <el-input v-model="ruleForm.companyName" class="change-width"></el-input>
              </el-form-item>
              <el-form-item label="公司详细地址">
                <el-input v-model="ruleForm.companyAddress" class="change-width"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" @click="handleUserInfoSubmit" :loading="becomeLoading">保存</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </el-card>
    </div>
  </div>
</template>

<script>
import { CmsCategoryApi, CommonQiniuApi, UserApi } from '@/api'

export default {
  name: 'UserEdit',
  data () {
    return {
      dialogImageUrl: '',
      dialogVisible: false,
      disabled: false,
      // 所属行业全部分类
      categoryList: [],
      // 编辑的信息
      ruleForm: {},
      becomeLoading: false,
      pageLoading: false
    }
  },
  methods: {
    initData () {
      const id = this.$route.query.id
      if (id) {
        this.ruleForm.id = id
        this.pageLoading = true
        UserApi.get(this.ruleForm.id).then((result) => {
          this.ruleForm = result
          this.pageLoading = false
        })

        CmsCategoryApi.all(1).then(result => {
          this.categoryList = result
        })
      }
    },
    /**
     * 提交按钮
     */
    handleUserInfoSubmit () {
      this.becomeLoading = true
      UserApi.save(this.ruleForm).then(() => {
        this.$message.success('保存成功')
        this.becomeLoading = false
        this.$router.back()
      }).catch(() => {
        this.becomeLoading = false
      })
    },

    /**
     * 图片上传返回url地址
     * @param file 文件对象流
     */
    handleHoverBeforeUpload (file) {
      const data = new FormData()
      data.append('file', file)
      CommonQiniuApi.upload(data).then((result) => {
        this.ruleForm.qrUrl = result.fileUrl
      })
    }
  },
  created () {
    this.initData()
  }
}
</script>

<style scoped>

.change-width {
  width: 330px;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 105px;
  height: 99px;
  line-height: 108px;
  text-align: center;
}

.userPic {
  width: 135px;
  height: 135px;
}

.user-head div {
  margin-top: 10px;
}

</style>
